<template>
    <div>
        <div class="bkColor">
            <headTitle :title="paymentDetails.projectName || paymentDetails.paymentName + title" :routerPath="routerPath">
            </headTitle>
            <div class="projectTitle">
                <div class="T" v-if="paymentDetails.projectName">{{ paymentDetails.projectName }}的项目付款详情</div>
                <div class="T" v-else>{{ paymentDetails.userName }}的{{ paymentDetails.paymentName }}付款详情</div>
                <div class="D">{{ getReviewedStatus(paymentDetails.state) }}</div>
                <div style="font-size:14px">流水号: {{ paymentDetails.paymentNo }}</div>
                <img v-show="paymentDetails.isSettle == 1" class="I" src="../assets/icon/ytg_img.png" alt="">
                <img v-show="paymentDetails.isSettle == 2" class="I" src="../assets/icon/yjj_img.png" alt="">
            </div>
            <div class="projectBody">
                <van-field v-if="paymentDetails.projectName" v-model="paymentDetails.projectName" label="项目信息" readonly
                    type="textarea" rows="1" autosize />
                <van-field v-model="projectBelong" label="归属单位" readonly />
                <van-field v-model="paymentDetails.userName" label="填写人" readonly />
                <van-field v-model="paymentDetails.supplier" label="供应商" readonly />
                <van-field v-model="paymentDetails.paymentName" label="费用类型" readonly />
                <van-field v-model="paymentDetails.price" label="付款金额（元）" readonly />
                <van-field v-model="paymentDetails.paymentTime" label="付款时间" readonly />
                <van-field v-model="paymentDetails.description" label="付款说明" readonly autosize type="textarea" />
                <downloadFile :fileList="paymentDetails.fileList"
                    :routerPath="isProject ? `/paymentDetails?id=${id}` : `/paymentDetails?id=${id}&route=/nonProject`">
                </downloadFile>
            </div>



            <process-details :list="paymentDetails.reviewedList" :avatar="paymentDetails.avatar"
                :copyList="paymentDetails.copyList" :createTime="paymentDetails.createTime"
                :userName="paymentDetails.userName"
                :routerPath="isProject ? `/paymentDetails?id=${id}` : `/paymentDetails?id=${id}&route=/nonProject`"></process-details>

            <!-- <div class="projectFoot"
                v-if="powerList.includes('2') && paymentDetails.state == 1 && paymentDetails.isSettle == 0">
                <van-button round block color="#CCCCCC" native-type="button" style="width: 35vw"
                    @click="finalConfirmation(2)">财务拒绝</van-button>
                <van-button round block type="info" native-type="button" style="width: 35vw"
                    @click="finalConfirmation(1)">财务确认</van-button>
            </div> -->




            <!-- <van-goods-action>
            <van-goods-action-icon v-if="equipment" icon="description" text="导出PDF" @click="pdf = true" />
            <van-goods-action-icon v-if="(paymentDetails.state == 0 && userId == paymentDetails.userId)" icon="revoke"
                text="撤销" @click="paymentRevoke" />
            <van-goods-action-icon icon="chat-o" text="评论" @click="isComment = true" />
            <van-goods-action-icon v-if="(paymentDetails.state == 2 && userId == paymentDetails.userId)" icon="description"
                text="重新提交" @click="resubmit" />
        </van-goods-action> -->

            <van-popup v-model="isComment" round closeable @click-close-icon="isComment = false">
                <sandBox :formId="id" :type="3"
                    :routerPath="isProject ? `/paymentDetails?id=${id}` : `/paymentDetails?id=${id}&route=/nonProject`"
                    @succesSend="pageUpdate" :promoterId="paymentDetails.userId"></sandBox>
            </van-popup>


            <van-popup v-model="pdf">
                <div id="printer">
                    <p>项目付款单</p>
                    <div style="text-align:left;font-size: 22px;font-weight: 600;">流水号：{{ paymentDetails.paymentNo }}</div>
                    <div style="text-align:left;font-weight: 600;margin-bottom: 10px;font-size: 30px;">单位：{{
                        paymentDetails.belong == 1 ? "广州大美自然文化科技有限公司" : 
                        paymentDetails.belong == 2 ? "广东中创科技信息有限公司" : 
                        paymentDetails.belong == 3 ? "广云仓" : 
                        paymentDetails.belong == 4 ? "大丰年" : "" }}</div>
                    <table style="width:100%">
                        <tr>
                            <td class="bold">时间</td>
                            <td colspan="2" class="bold">项目</td>
                            <td class="bold">付款类型</td>
                            <td colspan="2" class="bold">付款说明</td>
                            <td class="bold">金额(元)</td>
                            <td class="bold">发票等单据支出编号</td>
                            <td class="bold">发票等单据数量</td>
                            <td colspan="2" class="bold">备注</td>
                        </tr>

                        <tr>
                            <td>{{ paymentDetails.paymentTime }}</td>
                            <td colspan="2">{{ paymentDetails.projectName || '非项目' }}</td>
                            <td>{{ paymentDetails.paymentName }}</td>
                            <td colspan="2">{{ paymentDetails.description }}</td>
                            <td>{{ paymentDetails.price }}</td>
                            <td></td>
                            <td></td>
                            <td colspan="2"></td>
                        </tr>
                        <tr>
                            <td colspan="6" class="bold">合计</td>
                            <td>{{ paymentDetails.price }}</td>
                            <td></td>
                            <td></td>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                    <process-details :list="paymentDetails.reviewedList" :avatar="paymentDetails.avatar"
                        :copyList="paymentDetails.copyList" :createTime="paymentDetails.createTime"
                        :userName="paymentDetails.userName" :isPdf="true"></process-details>

                </div>
                <div style="display:flex;justify-content: center;align-items: center;">
                    <van-button round block type="info" native-type="button" style="width: 30vw;"
                        @click="exportPDF">确定导出</van-button>
                    <van-button round block type="info" native-type="button" style="width: 30vw;" color="#CCCCCC"
                        @click="pdf = false">取消导出</van-button>
                </div>

            </van-popup>
        </div>
        <div class="footContent">
            <div class="footButton" @click="pdf = true" v-if="equipment">
                <van-icon name="description" size="16px" />
                导出PDF
            </div>
            <div class="footButton" @click="paymentRevoke"
                v-if="(paymentDetails.state == 0 && userId == paymentDetails.userId)">
                <van-icon name="revoke" size="16px" />
                撤销
            </div>
            <div class="footButton" @click="isComment = true">
                <van-icon name="chat-o" size="16px" />
                评论
            </div>
            <div class="footButton" @click="resubmit" v-if="(paymentDetails.state == 2 && userId == paymentDetails.userId)">
                <van-icon name="records" size="16px" />
                重新提交
            </div>
            <div v-if="powerList.includes('2') && paymentDetails.state == 1 && paymentDetails.isSettle == 0"
                style="margin-left:auto">
                <van-button @click="finalConfirmation(2)" style="margin-right:20px">财务拒绝</van-button>
                <van-button type="info" @click="finalConfirmation(1)">财务同意</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import headTitle from "../components/headTitle.vue";
import { Field, Uploader, Button, Toast, Popup, GoodsAction, GoodsActionIcon, Dialog, Icon } from 'vant'
import Vue from 'vue';
Vue.use(Field)
Vue.use(Uploader)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Popup)
Vue.use(GoodsAction);
Vue.use(GoodsActionIcon);
Vue.use(Dialog)
Vue.use(Icon)

import { timeChange, projectStatusText } from '../units/units'
import { getPayment } from '../api/payment'
import { getToken } from '../units/auth'
import { paymentUpdateState, paymentDeltel } from '../api/payment'

import processDetails from '../components/processDetails.vue'
import downloadFile from "../components/downloadFile.vue";
import sandBox from "../components/inputBox/sandBox.vue";
import { ddShare } from "../units/dd";
export default {
    name: 'paymentDetails',
    components: {
        headTitle,
        processDetails,
        downloadFile,
        sandBox
    },
    mounted() {
        this.id = this.$route.query.id
        this.routerPath = this.$route.query.route ? this.$route.query.route : '/pjPayment'
        this.isProject = this.$route.query.route ? true : false
        this.getProgressDetail()
        this.getPowerList()
        this.getUserId()
        this.isMobile()
    },
    data() {
        return {
            title: '项目付款详情',
            id: '',
            paymentDetails: {
                additional: null,//是否非项目
                projectName: null,//项目名称
                userName: '',//付款人
                supplier: '',//供应商
                price: '',//价格
                paymentTime: '',//付款时间
                description: '',//付款说明
                paymentName: '',//付款类型
                fileList: [],//文件列表
                reviewedList: [],//审核人列表
                copyList: []//抄送人列表
            },
            isReviewImg: false,//是否预览图片
            reViewImg: '',//预览的图片
            powerList: [],//value 1总经理 2财务 3行政
            routerPath: '/pjPayment',//路由跳转记录
            isProject: true,//是否是项目
            isComment: false,//是否显隐评论框
            pdf: false,//是否显隐pdf预览
            htmlTitle: '',//导出pdf的文件名
            equipment: false,//判断是否为移动端
            userId: '',//当前用户的id
            projectBelong: ''
        }
    },
    methods: {
        getUserId() {
            this.userId = getToken('userId')
        },
        //获取权限渲染页面
        getPowerList() {
            this.powerList = JSON.parse(getToken('powerList') || '[]')
        },
        isMobile() {
            this.equipment = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? false : true
        },
        getProgressDetail() {
            getPayment(this.id).then(res => {
                this.paymentDetails = res.data.data
                this.projectBelong = this.paymentDetails.belong == 1 ? '大美' :
                                     this.paymentDetails.belong == 2 ? '中创' :
                                     this.paymentDetails.belong == 3 ? '广云仓' :
                                     this.paymentDetails.belong == 4 ? '大丰年' : ''
                this.htmlTitle = this.paymentDetails.projectName ? this.paymentDetails.projectName + '的付款单文件' : this.paymentDetails.userName + '的付款单文件'
                this.pageShare()
            })
        },
        reViewFile(url) {
            if (url.indexOf('.jpg') !== -1 || url.indexOf('.jpeg') !== -1 || url.indexOf('.png') !== -1) {
                this.isReviewImg = true
                this.reViewImg = url
                return
            }
            this.$router.push({
                path: "/preview",
                query: { url: url, routerPath: `/paymentDetails?id=${this.id}` },
            });
        },
        getTime(time) {
            return timeChange(time)
        },
        getReviewedStatus(s) {
            return projectStatusText(s)
        },
        //财务最后确认
        finalConfirmation(val) {
            paymentUpdateState(this.id, val).then(res => {
                if (res.data.code == 200) {
                    Toast.success('完成操作')
                    this.getProgressDetail()
                }
            })
        },
        // downloadFile(file) {
        //     if (this.equipment) {
        //         fetch(file.url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        //             const a = document.createElement('a')
        //             a.href = URL.createObjectURL(blob)
        //             console.log(a.href)
        //             a.download = file.fileName  // 下载文件的名字
        //             document.body.appendChild(a)
        //             a.click()
        //             document.body.removeChild(a) // 下载完成移除元素

        //         })
        //     } else {
        //         let link = document.createElement('a')
        //         link.style.display = 'none'
        //         let url = file.url//绝对地址
        //         link.href = url
        //         link.download = ''//文件名
        //         document.head.appendChild(link)
        //         link.click()
        //         document.head.removeChild(link)
        //     }
        // },
        // downloadAll() {
        //     this.paymentDetails.fileList.forEach(it => {
        //         console.log(it)
        //         this.downloadFile(it)
        //     })

        // },
        //添加评论后刷新数据
        pageUpdate() {
            this.getProgressDetail()
            this.isComment = false
        },




        exportPDF() {
            this.downloadHtmlToPdf()
        },
        paymentRevoke() {
            if (this.paymentDetails.state > 0 || this.userId !== this.paymentDetails.userId) {
                return
            }
            Dialog.confirm({
                message: `确定撤销？`,
                confirmButtonColor: '#0092fe'
            }).then(() => {
                paymentDeltel([this.id]).then(res => {
                    if (res.data.code == 200) {
                        Toast.success('撤销成功')
                        this.$router.replace('/pjPayment');
                    }
                })
            })
        },
        resubmit() {
            this.$router.push({
                path: "/newPjPayment",
                query: { additional: this.paymentDetails.additional, route: this.paymentDetails.additional == 0 ? "/pjPayment" : "/nonProject", detail: JSON.stringify(this.paymentDetails) },
            });
        },
        pageShare() {
            let name = this.paymentDetails.projectName ? this.paymentDetails.projectName + '的付款详情' : this.paymentDetails.userName + '的' + this.paymentDetails.paymentName + '付款详情'
            ddShare('/paymentDetails?id=' + this.id, name)
        }
    }
}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    background: #f6f6f6;
    padding-bottom: 9vh;
    position: relative;
    overflow: auto;
}

.projectTitle {
    width: 100vw;
    background: #fff;
    text-align: left;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 2vh;
    position: relative;

    .T {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1.5vh
    }

    .D {
        color: #F0A239;
        font-size: 1em;
    }

    .I {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        right: 70px;
    }
}

.projectBody {
    background: #fff;
}

/deep/ .van-cell {
    flex-wrap: wrap;
    margin-bottom: 1vh;
}

/deep/ .van-cell__title {
    color: #797979;
}

/deep/ .van-field__label,
.van-cell__title {
    width: 100vw;

}

/deep/ .van-cell::after {
    border-bottom: none;
}

.fileList {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    color: #797979;

    .title {
        text-align: left;
    }

    .fileImg {
        width: 80px;
        height: 80px;
    }

    .fileName {
        width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.reviewedList {
    padding: 10px 16px;
    background: #fff;
    overflow: auto;
    position: relative;

    .A {
        top: 0px !important;
    }

    .T {
        top: 0px !important
    }

    .B {
        top: 45px !important;
    }

    .U {
        top: 20px !important
    }

    .F {
        height: 70px !important;
        min-height: 90px;
        margin: 0 !important;
    }
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    margin-bottom: 2vh;
}

.reviewed {
    position: relative;
    // height: 160px;
    min-height: 100px;
    // margin-bottom: 120px;
    padding: 10px 20px;
    box-sizing: border-box;
    // overflow: auto;

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        position: absolute;
        left: 20px;
    }

    .status {
        font-size: 13px;
        position: absolute;
        top: 10px;
        left: 75px;
    }

    .time {
        font-size: 13px;
        position: absolute;
        top: 10px;
        right: 20px;
        color: #B4B4B4;
    }

    .userName {
        position: absolute;
        top: 30px;
        left: 75px;
        font-size: 13px;
        font-weight: 600;
    }

    .remark {
        width: 60vw;
        // position: absolute;
        // left: 40px;
        max-height: 55px;
        font-size: 13px;
        background: #f6f6f6;
        text-align: left;
        padding: 5px;
        line-height: normal;
        word-wrap: break-word;
        overflow: auto;
    }

    .broder {
        width: 70vw;
        min-height: 50px;
        position: absolute;
        top: 55px;
        left: 45px;
        border-left: 6px solid #E4E4E4;
        padding-left: 25px
    }
}



.reviewed:nth-last-of-type(1) .broder {
    border: unset;
}

.projectFoot {
    margin-top: 2vh;
    width: 100vw;
    background: #fff;
    padding: 20px 0px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
}

#printer {
    width: 1391px;
    height: 100%;
    padding: 35px;
}

#printer p {
    font-size: 50px;
    font-weight: 600;
}

#printer table {
    table-layout: fixed !important;
    border-collapse: collapse !important;

}

#printer tr {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #000;
    border-left: none;

}

#printer tr:first-child {
    border-left: 1px solid #000;
}

#printer td {
    height: 90px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    font-size: 22px;
    word-break: break-all;
}

#printer td:first-child {
    border-left: 1px solid #000;
}

#printer .bold {
    font-weight: 600;
}

/deep/ .van-goods-action {
    background: #f6f6f6;
}

/deep/ .van-goods-action-icon {
    min-width: 18%;
    background: #f6f6f6;
    font-weight: 600;
}


/deep/ .van-button {
    border-radius: 6px;
}

.footContent {
    width: 100vw;
    height: 9vh;
    padding: 0 10px;
    box-sizing: border-box;
    position: fixed;
    // top: 0;
    // right: 0;
    bottom: 0;
    left: 0;
    background: #f6f6f6;
    display: flex;
    align-items: center;
}

.footButton {
    width: 60px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}
</style>